body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-main{
  background-color: rgb(6, 16, 39);
  color: rgba(255, 255, 255, 0.75);
}

.bg-lmain{
  background-color: rgb(34, 60, 109);
  color: rgba(255, 255, 255, 0.75);
}

.bg-sub{
  background-color: rgb(21, 36, 81);
  color: rgba(255, 255, 255, 0.75);
}

.bg-lsub{
  background-color: rgb(49, 138, 183);
  color: rgba(255, 255, 255, 0.75);
}

.bg-none{
  background-color: rgb(77, 77, 78);
  color: rgba(255, 255, 255, 0.75);
}

.bg-b1{
  background-color: rgb(172, 174, 190);
  color: rgba(255, 255, 255, 0.75);
}

.bg-b2{
  background-color: rgb(114, 152, 191);
  color: rgba(255, 255, 255, 0.75);
}

.bg-b3{
  background-color: rgb(26, 103, 190);
  color: rgba(255, 255, 255, 0.75);
}

.bg-b4{
  background-color: rgb(4, 51, 191);
  color: rgba(255, 255, 255, 0.75);
}

.bg-green{
  background-color: rgb(45, 132, 87);
  color: rgba(255, 255, 255, 0.75);
}

.bg-yellow{
  background-color: rgb(183, 177, 34);
  color: rgba(255, 255, 255, 0.75);
}

.bg-orange{
  background-color: rgb(181, 113, 27);
  color: rgba(255, 255, 255, 0.75);
}

.bg-red{
  background-color: rgb(192, 37, 24);
  color: rgba(255, 255, 255, 0.75);
}

.bg-purple{
  background-color: rgb(136, 14, 88);
  color: rgba(255, 255, 255, 0.75);
}

.bg-black{
  background-color: rgb(10, 4, 18);
  color: rgba(255, 255, 255, 0.75);
}

.is-hovers:hover{
  filter: brightness(1.25);
  cursor: pointer;
}
