@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300&display=swap');
.templates_contents{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.templates_div{
  display: flex;
  justify-content: center;
  height: 50vh;
}
.templates_msg{
  font-size: 96px;
  color: white;
  font-weight: lighter;
  position: absolute;
  top: 30vh;
}
.terminal_div{
  height: 50vh;
}
.terminal_msg{
  font-family: 'Fira Code', monospace;
  color: white;
  overflow-wrap: anywhere;
  margin: 0 10vw;
  text-align: left;
  white-space: pre-wrap;
  line-height: 1.5rem;

}
.terminal-{
  animation: flash 0.7s linear infinite;
}
@keyframes flash{
  50%{
    opacity: 0;
  }
}
